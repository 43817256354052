import React from 'react';
import styled from '@emotion/styled';
import deviceImg from 'assets/images/landing-page-mock.png';
import Button from 'components/button/Button';
import MetaTags from 'components/MetaTags';
import { H1, P } from 'components/typography';
import WaveWrapper from 'components/WaveWrapper';
import dynamic from 'next/dynamic';
import Image from 'next/image';

const WhatIsDigito = dynamic(import('components/WhatIsDigito'), {
  ssr: false,
  loading: () => null
});

const HowItWorkSection = dynamic(import('components/HowItWorks'), {
  ssr: false,
  loading: () => null
});

const PricingPlanSection = dynamic(import('components/PricingSection'), {
  ssr: false,
  loading: () => null
});

const ContactUsSection = dynamic(import('components/ContactUsSection'), {
  ssr: false,
  loading: () => null
});

const BeigeBackground = styled.div`
  height: 95vh;
  z-index: 0;
  background: ${({ theme }) => theme.palette.BEIGE};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`;

const Wrapper = styled.div`
  background: ${({ theme }) => theme.palette.BEIGE};
  position: relative;
  align-items: center;
  color: white;
  display: flex;
  min-height: 100vh;
  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}) {
    justify-content: center;
  }
`;

const ContentWrapper = styled.div`
  z-index: 999;
  align-items: center;
  color: ${({ theme }) => theme.palette.BLACK};
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: ${({ theme }) => theme.width.medium};
  padding: ${({ theme }) => theme.space.xlarge};
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}) {
    padding-top: unset;
    flex-direction: column;
  }
`;

const StyledWaveWrapper = styled(WaveWrapper)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: -10px;
`;

const PitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: ${({ theme }) => theme.space.medium};
  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}) {
    align-items: center;
    text-align: center;
  }
`;

const Title = styled(H1)`
  font-family: 'Rajdhani', sans-serif;
  font-size: 52px;
  letter-spacing: 5px;
  margin-bottom: -5px;
`;

const SubTitle = styled.span`
  font-size: 18px;
  letter-spacing: 2px;
  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}) {
    font-size: 16px;
  }
`;

const Text = styled(P)`
  line-height: 20px;
  font-size: 16px;
  letter-spacing: 2px;
  max-width: ${({ theme }) => theme.width.xsmall};
  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}) {
    font-size: 14px;
  }
`;

const ImgWrapper = styled.div`
  margin: ${({ theme }) => theme.space.medium};
  width: 45%;
  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}) {
    width: 42%;
    margin-top: ${({ theme }) => theme.space.xxxlarge};
  }
  @media (max-width: ${({ theme }) => theme.breakPoints.mobileS}) {
    width: 45%;
    margin: unset;
  }
`;

const Index = () => {
  return (
    <>
      <MetaTags
        title="DIGITO - Allt du behöver i en länk!"
        description="Hantera och dela dina sociala kanaler på en och samma plattform"
      />
      <BeigeBackground />
      <Wrapper>
        <ContentWrapper>
          <PitchWrapper>
            <Title>DIGITO</Title>
            <SubTitle>- Allt du behöver i en länk!</SubTitle>
            <Text>
              Digito ger dig frihet att samla alla dina sociala kanaler på en
              samlad plattform, eller varför inte använda det som ett visitkort!
            </Text>
            <Button href="/login" variant="contained">
              Registrera dig nu!
            </Button>
          </PitchWrapper>
          <ImgWrapper>
            <Image
              src={deviceImg}
              alt="digito-logo"
              layout="responsive"
              placeholder="blur"
            />
          </ImgWrapper>
        </ContentWrapper>
        <StyledWaveWrapper direction="top" viewBox="0 0 1440 320">
          <path
            fillOpacity="1"
            d="M0,128L80,128C160,128,320,128,480,149.3C640,171,800,213,960,234.7C1120,256,1280,256,1360,256L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </StyledWaveWrapper>
      </Wrapper>
      <WhatIsDigito />
      <HowItWorkSection />
      <PricingPlanSection />
      <ContactUsSection />
    </>
  );
};

Index.displayName = 'Index';

Index.layoutOptions = {
  backgroundColor: '#f4f4f4',
  noBottomSpacing: true,
  footer: true
};

export default Index;
