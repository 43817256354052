import React from 'react';
import Head from 'next/head';

const MetaTags = ({
  title = 'Digito',
  description,
  url = 'https://digito.se',
  img = 'https://digito.se/share-pic.jpg'
}) => (
  <Head>
    <title>{title}</title>
    <meta name="description" content={description} />

    {/* <!-- Google / Search Engine Tags --> */}
    <meta itemProp="name" content={title} />
    <meta itemProp="description" content={description} />
    <meta itemProp="image" content={img} />

    {/* <!-- Facebook Meta Tags --> */}
    <meta property="og:url" content={url} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={img} />

    {/* <!-- Twitter Meta Tags --> */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={img} />
  </Head>
);

export default MetaTags;
