import styled from '@emotion/styled';

const Wave = styled.svg`
  margin-bottom: -10px;
  background: ${({ theme }) =>
    `-moz-linear-gradient(bottom, ${theme.palette.GREEN} 0%, ${theme.palette.BEIGE} 30%);`};
  background: ${({ theme }) =>
    `-webkit-linear-gradient(bottom, ${theme.palette.GREEN} 0%, ${theme.palette.BEIGE} 30%);`};
  background: ${({ theme, direction }) =>
    `linear-gradient(to ${direction === 'top' ? 'top' : 'bottom'}, ${
      theme.palette.GREEN
    } 0%, ${theme.palette.BEIGE} 20%);`};
  path {
    fill: ${({ theme }) => theme.palette.GREEN};
  }
`;

const WaveWrapper = ({ children, ...props }) => {
  return (
    <Wave xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" {...props}>
      {children}
    </Wave>
  );
};
export default WaveWrapper;
